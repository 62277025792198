import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { BaseService } from '../@base/base.service';
import { JwtService } from '../@base/jwt.service';
import { NgxVnptChatService } from 'ngx-vnpt-chat';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  private loggedInSubject: BehaviorSubject<boolean>;
  public loggedIn$: Observable<boolean>;

  public get username(): string | null {
    return localStorage.getItem('username');
  }
  public set username(value: string | null) {
    if (value) {
      localStorage.setItem('username', value);
      this.loggedInSubject.next(true);
    } else {
      localStorage.removeItem('username');
      this.loggedInSubject.next(false);
    }
  }
  public get displayName(): string | null {
    return localStorage.getItem('displayName');
  }
  public set displayName(value: string | null) {
    if (value) {
      localStorage.setItem('displayName', value);
    } else {
      localStorage.removeItem('displayName');
    }
  }

  public get loggedIn() {
    return this.loggedInSubject.value;
  }

  constructor(
    private http: HttpClient,
    private chatService: NgxVnptChatService
  ) {
    super();
    this.loggedInSubject = new BehaviorSubject<any>(!!this.username);
    this.loggedIn$ = this.loggedInSubject.asObservable();
    if (this.username) {
      this.loginChat(this.username);
    }
  }

  async login(username: string, displayName: string) {
    this.username = username;
    this.displayName = displayName;
    this.loginChat(this.username);
  }

  async loginChat(username: string) {
    const appId = environment.vchat_app_id;
    const appKey = environment.vchat_app_key;

    const jwtService = new JwtService();
    const token = jwtService.signToken(
      {
        appId,
        userId: username,
      },
      appKey
    );
    await this.chatService
      .login(appId, token)
      .then(() => {
        console.log('done');
      })
      .catch(console.error);
  }

  logout() {
    this.username = null;
    this.displayName = null;
    this.chatService.logout();
  }
}
