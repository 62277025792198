import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.log(`${operation} failed: ${error}`);

      if (result != null) {
        return of(result);
      }

      return throwError(error);
    };
  }

  protected log(message: string) {
    console.log(`${this.className}: ${message}`);
  }

  protected get className() {
    return this.constructor.name;
  }
}
