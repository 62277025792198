"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Chat = void 0;
const vnptchat_1 = require("../vnptchat");
const enum_1 = require("../enum");
const chat_member_1 = require("./chat-member");
class Chat {
    //#endregion
    constructor(chat) {
        //#region properties
        this.vchat = vnptchat_1.VNPTChat.instance;
        if (!chat) {
            return;
        }
        this.id = chat.id;
        this.type = chat.type;
        this.title = chat.title;
        this.ownerId = chat.ownerId;
        this.members = chat.members.map((m) => new chat_member_1.ChatMember(m));
        this.pinnedMessageId = chat.pinnedMessageId;
        this.permissions = chat.permissions;
        this.lastMessageId = chat.lastMessageId;
        this.avatarColor = chat.avatarColor;
        this.avatar = chat.avatar;
        this.photoCount = chat.photoCount;
        this.documentCount = chat.documentCount;
        this.createdAt = chat.createdAt;
        this.updatedAt = chat.updatedAt;
    }
    //#region common info
    get isIndividual() {
        return this.type == enum_1.ChatType.Individual;
    }
    get isGroup() {
        return this.type == enum_1.ChatType.Group;
    }
    get safeTitle() {
        var _a, _b, _c;
        if (this.isGroup) {
            return this.title;
        }
        if (this.isIndividual) {
            const contact = this.userPartner && this.vchat.contact(this.userPartner.id);
            return (_c = (_a = contact === null || contact === void 0 ? void 0 : contact.displayName) !== null && _a !== void 0 ? _a : (_b = this.userPartner) === null || _b === void 0 ? void 0 : _b.displayName) !== null && _c !== void 0 ? _c : "Unknown";
        }
        return "";
    }
    get avatarUrl() {
        const avatar = this.avatar && this.avatar[0];
        const fileDownload = avatar && this.vchat.fileDownload(avatar.fileId);
        return fileDownload === null || fileDownload === void 0 ? void 0 : fileDownload.objectUrl;
    }
    get partner() {
        if (!this.isIndividual) {
            return;
        }
        const currentUser = this.vchat.currentUser;
        return this.members.find((m) => m.userId != (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id));
    }
    get userPartner() {
        return this.partner && this.vchat.user(this.partner.userId);
    }
    get unreadCount() {
        var _a, _b;
        return ((_b = (_a = this.members.find((m) => m.userId == this.vchat.currentUserId)) === null || _a === void 0 ? void 0 : _a.unreadCount) !== null && _b !== void 0 ? _b : 0);
    }
    get maxIncomingReadId() {
        var _a;
        return (_a = this.members.find((m) => m.userId == this.vchat.currentUserId)) === null || _a === void 0 ? void 0 : _a.maxReadId;
    }
    get maxOutgoingReadId() {
        return this.members
            .filter((m) => m.userId != this.vchat.currentUserId)
            .map((m) => m.maxReadId)
            .sort((a, b) => a.localeCompare(b))[0];
    }
    loadPartner() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.partner) {
                return;
            }
            if (!this.userPartner) {
                yield this.vchat.getUser(this.partner.userId);
            }
            return this.userPartner;
        });
    }
    loadAvatar() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.avatarUrl) {
                const avatar = this.avatar && this.avatar[0];
                avatar &&
                    (yield this.vchat.downloadGroupChatAvatar(this.id, avatar.fileId));
            }
            return this.avatarUrl;
        });
    }
    //#endregion
    //#region Members
    get me() {
        return (this.vchat.currentUser &&
            this.members.find((m) => { var _a; return m.userId == ((_a = this.vchat.currentUser) === null || _a === void 0 ? void 0 : _a.id); }));
    }
    get isOwner() {
        return (!!this.vchat.currentUser && this.ownerId == this.vchat.currentUser.id);
    }
    get isAdmin() {
        var _a;
        return (_a = this.me) === null || _a === void 0 ? void 0 : _a.isAdmin;
    }
    get pinOrder() {
        const user = this.vchat.currentUser;
        const pinnedChatId = user === null || user === void 0 ? void 0 : user.pinnedChatId;
        if (!(pinnedChatId === null || pinnedChatId === void 0 ? void 0 : pinnedChatId.length)) {
            return -1;
        }
        return pinnedChatId.indexOf(this.id);
    }
    get isPinned() {
        return this.pinOrder >= 0;
    }
    hasMember(userId) {
        return this.members.some((m) => m.userId == userId);
    }
    //#endregion
    //#region Permissions
    get canDelete() {
        return this.isGroup && this.isOwner;
    }
    hasPermission(permission) {
        var _a, _b;
        return (((_a = this.me) === null || _a === void 0 ? void 0 : _a.isOwner) ||
            ((!this.permissions || this.permissions[permission]) &&
                ((_b = this.me) === null || _b === void 0 ? void 0 : _b.hasPermission(permission))));
    }
    get canSendText() {
        return this.isIndividual || this.hasPermission("allowSendText");
    }
    get canSendPhoto() {
        return this.isIndividual || this.hasPermission("allowSendPhoto");
    }
    get canSendDocument() {
        return this.isIndividual || this.hasPermission("allowSendDocument");
    }
    get canShareContact() {
        return this.isIndividual || this.hasPermission("allowShareContact");
    }
    get canSendSticker() {
        return this.isIndividual || this.hasPermission("allowSendSticker");
    }
    get canPinMessage() {
        return this.isIndividual || this.hasPermission("allowPinMessages");
    }
    get canChangeInfo() {
        return this.isGroup && this.hasPermission("allowChangeInfo");
    }
    get canInviteUsers() {
        return this.isGroup && this.hasPermission("allowInviteUsers");
    }
    //#endregion
    //#region Messages
    get messages() {
        return this.vchat.chatMessages(this.id);
    }
    get messagesLoadingBefore() {
        return this.vchat.messagesLoadingBefore(this.id);
    }
    get messagesLoadingAfter() {
        return this.vchat.messagesLoadingAfter(this.id);
    }
    /**
     * @deprecated This method will be removed in future. Use {@link messagesLoadingBefore} instead
     */
    get messagesLoading() {
        return this.vchat.chatMessagesLoading(this.id);
    }
    get hasReachedMaxBefore() {
        return this.vchat.hasReachedMaxBefore(this.id);
    }
    get hasReachedMaxAfter() {
        return this.vchat.hasReachedMaxAfter(this.id);
    }
    /**
     * @deprecated This method will be removed in future. Use {@link hasReachedMaxBefore} instead
     */
    get hasReachedMax() {
        return this.vchat.hasReachedMax(this.id);
    }
    get lastMessage() {
        return this.lastMessageId
            ? this.vchat.message(this.lastMessageId)
            : undefined;
    }
    get repliedMessage() {
        return this.vchat.getReplyToMessage(this.id);
    }
    get typingText() {
        return this.vchat.getTypingText(this.id);
    }
    getLastMessage() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.lastMessage && this.lastMessageId) {
                return this.vchat.getMessage(this.id, this.lastMessageId);
            }
            return undefined;
        });
    }
    loadMoreMessagesBefore() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.vchat.loadMoreMessagesBefore(this.id);
        });
    }
    loadMoreMessagesAfter() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.vchat.loadMoreMessagesAfter(this.id);
        });
    }
    loadMessagesAround(messageId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.vchat.loadMessagesAround(this.id, messageId);
        });
    }
    sendText(text, options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.vchat.sendTextToChat(this.id, text, options);
        });
    }
    sendPhoto(photo, options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.vchat.sendPhotoToChat(this.id, photo, options);
        });
    }
    sendDocument(file, options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.vchat.sendDocumentToChat(this.id, file, options);
        });
    }
    shareContact(sharedUserId, options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.vchat.shareContactToChat(this.id, sharedUserId, options);
        });
    }
    forwardMessage(fromChatId, messageId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.vchat.forwardMessageToChat(this.id, fromChatId, messageId);
        });
    }
    sendAction(action) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.vchat.sendActionToChat(this.id, action);
        });
    }
    sendCallLog(callType, startedAt, endedAt, callDuration, endReason) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.vchat.sendCallLogToChat(this.id, callType, startedAt, endedAt, callDuration, endReason);
        });
    }
    editTextMessage(messageId, text, options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.vchat.editTextMessage(this.id, messageId, text, options);
        });
    }
    editPhotoMessage(messageId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.vchat.editPhotoMessage(this.id, messageId, options);
        });
    }
    editDocumentMessage(messageId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.vchat.editDocumentMessage(this.id, messageId, options);
        });
    }
    deleteMessage(messageId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.vchat.deleteMessage(this.id, messageId);
        });
    }
    cancelMessage(draftId) {
        return this.vchat.cancelMessage(draftId);
    }
    setReplyToMessage(messageId) {
        return this.vchat.setReplyToMessage(this.id, messageId);
    }
    cancelReplyToMessage() {
        return this.vchat.cancelReplyToMessage(this.id);
    }
    setTypingText(text) {
        return this.vchat.setTypingText(this.id, text);
    }
    cancelTypingText() {
        return this.vchat.cancelTypingText(this.id);
    }
}
exports.Chat = Chat;
