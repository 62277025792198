"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MemberStatus = void 0;
var MemberStatus;
(function (MemberStatus) {
    MemberStatus["Creator"] = "creator";
    MemberStatus["Administrator"] = "administrator";
    MemberStatus["Member"] = "member";
    MemberStatus["Restrict"] = "restrict_member";
    MemberStatus["Left"] = "left";
    MemberStatus["Kicked"] = "kicked";
})(MemberStatus = exports.MemberStatus || (exports.MemberStatus = {}));
