<div class="layout-topbar">
  <a class="logo">
    <h1 class="p-m-0" style="color: blue">VNPT CHAT</h1>
  </a>
  <ul #topbarMenu class="topbar-menu">
    <li>
      <a routerLink="/">Trang chủ</a>
    </li>
    <li>
      <a routerLink="/popup">Popup</a>
    </li>
    <li style="min-width: 150px" *ngIf="!loggedIn">
      <a routerLink="/auth/login">Đăng Nhập</a>
    </li>
    <li style="min-width: 150px" *ngIf="loggedIn" class="topbar-submenu">
      <a tabindex="0" (click)="toggleMenu($event, 1)">
        <vchat-user-avatar
          *ngIf="currentUser"
          [user]="currentUser"
        ></vchat-user-avatar>

        <span>
          {{ displayName }}
        </span>
      </a>
      <ul
        class="sub-menu"
        [@overlayMenuAnimation]="'visible'"
        *ngIf="activeMenuIndex === 1"
        (@overlayMenuAnimation.start)="onOverlayMenuEnter($event)"
      >
        <li>
          <a (click)="logout()">Đăng xuất</a>
        </li>
      </ul>
    </li>
  </ul>
</div>
