"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StickerApi = exports.FileApi = exports.MessageApi = exports.ChatApi = exports.ContactApi = exports.ContactGroupApi = exports.AccountApi = exports.UserApi = void 0;
var UserApi;
(function (UserApi) {
    UserApi["GetById"] = "user.getById";
    UserApi["GetByExternalId"] = "user.getByExternalId";
    UserApi["Find"] = "user.find";
    UserApi["DownloadAvatar"] = "user.downloadAvatar";
})(UserApi = exports.UserApi || (exports.UserApi = {}));
var AccountApi;
(function (AccountApi) {
    AccountApi["Login"] = "account.login";
    AccountApi["Logout"] = "account.logout";
    AccountApi["GetProfile"] = "account.getProfile";
    AccountApi["IsLoggedIn"] = "account.isLoggedIn";
    AccountApi["GetChannels"] = "account.getChannels";
    AccountApi["RegisterFcmToken"] = "account.registerFcmToken";
    AccountApi["PinChat"] = "account.pinChat";
})(AccountApi = exports.AccountApi || (exports.AccountApi = {}));
var ContactGroupApi;
(function (ContactGroupApi) {
    ContactGroupApi["GetAll"] = "contactGroup.getAll";
    ContactGroupApi["GetById"] = "contactGroup.getById";
    ContactGroupApi["Create"] = "contactGroup.create";
    ContactGroupApi["Update"] = "contactGroup.update";
    ContactGroupApi["Delete"] = "contactGroup.delete";
})(ContactGroupApi = exports.ContactGroupApi || (exports.ContactGroupApi = {}));
var ContactApi;
(function (ContactApi) {
    ContactApi["GetAll"] = "contact.getAll";
    ContactApi["GetById"] = "contact.getById";
    ContactApi["Create"] = "contact.create";
    ContactApi["Update"] = "contact.update";
    ContactApi["Delete"] = "contact.delete";
})(ContactApi = exports.ContactApi || (exports.ContactApi = {}));
var ChatApi;
(function (ChatApi) {
    ChatApi["GetAll"] = "chat.getAll";
    ChatApi["GetById"] = "chat.getById";
    ChatApi["GetByReceiver"] = "chat.getByReceiver";
    ChatApi["CreateGroup"] = "chat.createGroup";
    ChatApi["UpdateGroup"] = "chat.updateGroup";
    ChatApi["Delete"] = "chat.deleteGroup";
    ChatApi["AddMembers"] = "chat.addMembers";
    ChatApi["Leave"] = "chat.leave";
    ChatApi["RemoveMember"] = "chat.removeMember";
    ChatApi["CreateAlbum"] = "chat.createAlbum";
    ChatApi["GetAlbums"] = "chat.getAlbums";
    ChatApi["GetMessages"] = "chat.getMessages";
    ChatApi["GetDocumentMessages"] = "chat.getSharedDocuments";
    ChatApi["GetPhotoMessages"] = "chat.getSharedMedias";
    ChatApi["UpdateMaxReadId"] = "chat.updateMaxReadId";
    ChatApi["DownloadAvatar"] = "chat.downloadAvatar";
    ChatApi["PinMessage"] = "chat.pinMessage";
    ChatApi["UnpinAllMessage"] = "chat.unpinAllMessage";
    ChatApi["UpdatePermissions"] = "chat.updatePermissions";
    ChatApi["PromoteAdmin"] = "chat.promoteAdmin";
    ChatApi["DismissAdmin"] = "chat.dismissAdmin";
    ChatApi["RestrictMember"] = "chat.restrictMember";
    ChatApi["RemoveRestrictMember"] = "chat.removeRestrictMember";
})(ChatApi = exports.ChatApi || (exports.ChatApi = {}));
var MessageApi;
(function (MessageApi) {
    MessageApi["GetMessagesAfter"] = "message.getMessagesAfter";
    MessageApi["GetById"] = "message.getById";
    MessageApi["SendText"] = "message.sendText";
    MessageApi["SendDocument"] = "message.sendDocument";
    MessageApi["SendPhoto"] = "message.sendPhoto";
    MessageApi["SendVideo"] = "message.sendVideo";
    MessageApi["SendGallery"] = "message.sendGallery";
    MessageApi["SendContact"] = "message.sendContact";
    MessageApi["SendAction"] = "message.sendAction";
    MessageApi["SendCallLog"] = "message.sendCallLog";
    MessageApi["EditText"] = "message.editText";
    MessageApi["EditDocument"] = "message.editDocument";
    MessageApi["EditPhoto"] = "message.editPhoto";
    MessageApi["Delete"] = "message.delete";
    MessageApi["Forward"] = "message.forward";
    MessageApi["DownloadPhoto"] = "message.downloadPhoto";
    MessageApi["DownloadDocument"] = "message.downloadDocument";
    MessageApi["SendSticker"] = "message.sendSticker";
})(MessageApi = exports.MessageApi || (exports.MessageApi = {}));
var FileApi;
(function (FileApi) {
    FileApi["StartUpload"] = "file.startUpload";
    FileApi["UploadChunk"] = "file.uploadChunk";
    FileApi["FinalUpload"] = "file.finalUpload";
    // Cancel Download
    FileApi["CancelDownload"] = "file.cancelDownload";
    FileApi["CancelUpload"] = "file.cancelUpload";
})(FileApi = exports.FileApi || (exports.FileApi = {}));
var StickerApi;
(function (StickerApi) {
    StickerApi["GetPackages"] = "sticker.getPackages";
    StickerApi["GetAll"] = "sticker.getAll";
    StickerApi["GetById"] = "sticker.getById";
    StickerApi["Download"] = "sticker.download";
    StickerApi["DownloadStarted"] = "sticker.download.started";
    StickerApi["DownloadReceived"] = "sticker.download.received";
    StickerApi["DownloadEnded"] = "sticker.download.ended";
    StickerApi["DownloadFailed"] = "sticker.download.failed";
    StickerApi["DownloadCancelled"] = "sticker.download.cancelled";
})(StickerApi = exports.StickerApi || (exports.StickerApi = {}));
