<div class="main-wrapper">
  <div class="left-panel-wrapper">
    <p-tabView [style]="{ height: '100%' }">
      <p-tabPanel header="Chats">
        <ng-template pTemplate="header">
          <div
            *ngIf="unreadCount"
            pBadge
            [value]="unreadCountString"
            severity="danger"
          >
            Chats
          </div>
          <div *ngIf="!unreadCount">Chats</div>
        </ng-template>
        <div class="chats-list-tab">
          <vchat-chats-list (selected)="onChatSelected($event)">
          </vchat-chats-list>
        </div>

        <button
          pButton
          type="button"
          icon="pi pi-plus"
          iconPos="left"
          class="button"
          (click)="showCreateGroupChat()"
        ></button>
      </p-tabPanel>
      <p-tabPanel header="Contacts">
        <div class="contacts-list-tab">
          <vchat-contacts-list (selected)="onContactSelected($event)">
          </vchat-contacts-list>
        </div>

        <button
          pButton
          type="button"
          icon="pi pi-plus"
          iconPos="left"
          class="button"
          (click)="showAddContact()"
        ></button>
      </p-tabPanel>

      <p-tabPanel header="Settings"> Content 3 </p-tabPanel>
    </p-tabView>
  </div>

  <div
    class="right-panel-wrapper"
    [class.show-info]="showInfo"
    *ngIf="selectedChat && selectedChat.isGroup"
  >
    <vchat-chat-group
      *ngIf="selectedChat && selectedChat.isGroup"
      [group]="selectedChat"
      class="chat-box"
      (headerClick)="showInfo = !showInfo"
      (senderClick)="onSenderClick($event)"
    >
      <ng-template vchatTemplate="header" let-group>
        <vchat-chat-group-header [group]="group">
          <ng-template vchatTemplate="avatar" let-group>
            <vchat-group-avatar [group]="group"></vchat-group-avatar>
          </ng-template>
          <!-- <ng-template vchatTemplate="title" let-group> title </ng-template>
          <ng-template vchatTemplate="subtitle" let-group>
            subtitle
          </ng-template> -->
        </vchat-chat-group-header>
      </ng-template>
      <!-- <ng-template vchatTemplate="messages" let-group>
        <vchat-messages-list
          [chat]="group"
          (senderClick)="onSenderClick($event)"
        >
        </vchat-messages-list>
      </ng-template> -->
      <ng-template vchatTemplate="input" let-group>
        <vchat-chat-input [chatId]="group.id" [(text)]="text">
          <ng-template vchatTemplate="inputText">
            <input [(ngModel)]="text" class="chat-input-text" />
          </ng-template>
        </vchat-chat-input>
      </ng-template>
    </vchat-chat-group>
    <div class="info-box">
      <div class="header">
        <a (click)="showInfo = false">
          <i class="pi pi-times"></i>
        </a>
        Profile
      </div>
      <vchat-group-info class="body" [group]="selectedChat"></vchat-group-info>
    </div>
  </div>

  <div
    class="right-panel-wrapper"
    [class.show-info]="showInfo"
    *ngIf="selectedUser"
  >
    <vchat-chat-user
      *ngIf="selectedUser"
      [user]="selectedUser"
      class="chat-box"
      (headerClick)="showInfo = !showInfo"
    >
      <ng-template vchatTemplate="header" let-user>
        <vchat-chat-user-header [user]="user">
          <ng-template vchatTemplate="avatar" let-user>
            <vchat-user-avatar [user]="user"></vchat-user-avatar>
          </ng-template>
          <!-- <ng-template vchatTemplate="title" let-group> title </ng-template>
          <ng-template vchatTemplate="subtitle" let-group>
            subtitle
          </ng-template> -->
        </vchat-chat-user-header>
      </ng-template>
      <ng-template vchatTemplate="messages" let-chat>
        <vchat-messages-list [chat]="chat"> </vchat-messages-list>
      </ng-template>
      <ng-template vchatTemplate="input" let-user let-chat="chat">
        <vchat-chat-input [userId]="user.id" [chatId]="chat?.id">
        </vchat-chat-input>
      </ng-template>
    </vchat-chat-user>

    <div class="info-box">
      <div class="header">
        <a (click)="showInfo = false">
          <i class="pi pi-times"></i>
        </a>
        Profile
      </div>
      <vchat-user-info class="body" [user]="selectedUser"></vchat-user-info>
    </div>
  </div>
</div>
