"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Contact = void 0;
const vnptchat_1 = require("../vnptchat");
class Contact {
    constructor(contact) {
        this.vchat = vnptchat_1.VNPTChat.instance;
        if (!contact) {
            return;
        }
        this.userId = contact.userId;
        this.displayName = contact.displayName;
        this.groupId = contact.groupId;
        this.createdAt = contact.createdAt;
        this.updatedAt = contact.updatedAt;
    }
    get user() {
        return this.vchat.user(this.userId);
    }
    loadUser() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.user) {
                return this.user;
            }
            return this.vchat.getUser(this.userId);
        });
    }
}
exports.Contact = Contact;
