"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownloadEvent = exports.MessageEvent = exports.ChatEvent = exports.ContactEvent = exports.ContactGroupEvent = exports.UserEvent = exports.SocketEvent = void 0;
var SocketEvent;
(function (SocketEvent) {
    SocketEvent["StateChanged"] = "socket.stateChanged";
})(SocketEvent = exports.SocketEvent || (exports.SocketEvent = {}));
var UserEvent;
(function (UserEvent) {
    UserEvent["OnlineStatusChanged"] = "user.onlineStatusChanged";
    UserEvent["Edited"] = "user.edited";
})(UserEvent = exports.UserEvent || (exports.UserEvent = {}));
var ContactGroupEvent;
(function (ContactGroupEvent) {
    ContactGroupEvent["Created"] = "contactGroup.created";
    ContactGroupEvent["Edited"] = "contactGroup.edited";
    ContactGroupEvent["Deleted"] = "contactGroup.deleted";
})(ContactGroupEvent = exports.ContactGroupEvent || (exports.ContactGroupEvent = {}));
var ContactEvent;
(function (ContactEvent) {
    ContactEvent["Created"] = "contact.created";
    ContactEvent["Edited"] = "contact.edited";
    ContactEvent["Deleted"] = "contact.deleted";
})(ContactEvent = exports.ContactEvent || (exports.ContactEvent = {}));
var ChatEvent;
(function (ChatEvent) {
    ChatEvent["Created"] = "chat.created";
    ChatEvent["Edited"] = "chat.edited";
    ChatEvent["Deleted"] = "chat.deleted";
    ChatEvent["NewMember"] = "chat.memberAdded";
    ChatEvent["RemoveMember"] = "chat.memberRemoved";
    ChatEvent["ReadStateChanged"] = "chat.readStateChanged";
})(ChatEvent = exports.ChatEvent || (exports.ChatEvent = {}));
var MessageEvent;
(function (MessageEvent) {
    MessageEvent["Received"] = "message.received";
    MessageEvent["Edited"] = "message.edited";
    MessageEvent["Deleted"] = "message.deleted";
})(MessageEvent = exports.MessageEvent || (exports.MessageEvent = {}));
var DownloadEvent;
(function (DownloadEvent) {
    DownloadEvent["Started"] = "download.started";
    DownloadEvent["Received"] = "download.received";
    DownloadEvent["Ended"] = "download.ended";
    DownloadEvent["Failed"] = "download.failed";
    DownloadEvent["Cancelled"] = "download.cancelled";
})(DownloadEvent = exports.DownloadEvent || (exports.DownloadEvent = {}));
