"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatMember = void 0;
const member_status_1 = require("../enum/member-status");
class ChatMember {
    constructor(member) {
        if (!member) {
            return;
        }
        this.userId = member.userId;
        this.status = member.status;
        this.customTitle = member.customTitle;
        this.untilDate = member.untilDate && new Date(member.untilDate);
        this.restrictPermissions = member.restrictPermissions;
        this.maxReadId = member.maxReadId;
        this.unreadCount = member.unreadCount;
    }
    get isAdmin() {
        return this.status == member_status_1.MemberStatus.Administrator;
    }
    get isOwner() {
        return this.status == member_status_1.MemberStatus.Creator;
    }
    get isRestrict() {
        return this.status == member_status_1.MemberStatus.Restrict;
    }
    hasPermission(permission) {
        return !this.restrictPermissions || this.restrictPermissions[permission];
    }
    get title() {
        var _a, _b;
        if (this.isOwner) {
            return (_a = this.customTitle) !== null && _a !== void 0 ? _a : "owner";
        }
        if (this.isAdmin) {
            return (_b = this.customTitle) !== null && _b !== void 0 ? _b : "admin";
        }
        return "";
    }
}
exports.ChatMember = ChatMember;
