"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Message = void 0;
const vnptchat_1 = require("../vnptchat");
class Message {
    constructor(message) {
        this.vchat = vnptchat_1.VNPTChat.instance;
        if (!message) {
            return;
        }
        this.id = message.id;
        this.chatId = message.chatId;
        this.senderId = message.senderId;
        this.type = message.type;
        this.state = message.state;
        this.text = message.text;
        this.entities = message.entities;
        this.photo = message.photo;
        this.document = message.document;
        this.contact = message.contact;
        this.callInfo = message.callInfo;
        this.replyTo = message.replyTo;
        this.forwardedMessage = new Message(message.forwardedMessage);
        this.createdAt = message.createdAt;
        this.updatedAt = message.updatedAt;
        this.sticker = message.sticker;
    }
    get isOwner() {
        const currentUser = this.vchat.currentUser;
        return currentUser && currentUser.id == this.senderId;
    }
    get isTextMessage() {
        return this.type == "text";
    }
    get isPhotoMessage() {
        return this.type == "photo";
    }
    get isDocumentMessage() {
        return this.type == "file" || this.type == "document";
    }
    get isContactMessage() {
        return this.type == "contact";
    }
    get isForwardMessage() {
        return this.type == "forward";
    }
    get isSystemMessage() {
        return this.type == "system";
    }
    get isDeletedMessage() {
        return this.type == "deleted";
    }
    get isDraftTextMessage() {
        return this.type == "draft_text";
    }
    get isDraftPhotoMessage() {
        return this.type == "draft_photo";
    }
    get isDraftDocumentMessage() {
        return this.type == "draft_document";
    }
    get isDraftContactMessage() {
        return this.type == "draft_contact";
    }
    get isDraftForwardMessage() {
        return this.type == "draft_forward";
    }
    get isDraftMessage() {
        return (this.isDraftTextMessage ||
            this.isDraftPhotoMessage ||
            this.isDraftDocumentMessage ||
            this.isDraftContactMessage ||
            this.isDraftForwardMessage);
    }
    get canReply() {
        return (this.isTextMessage ||
            this.isPhotoMessage ||
            this.isDocumentMessage ||
            this.isContactMessage ||
            this.isForwardMessage);
    }
    get canForward() {
        return (this.isTextMessage ||
            this.isPhotoMessage ||
            this.isDocumentMessage ||
            this.isContactMessage ||
            this.isForwardMessage);
    }
    get canEdit() {
        return (this.isOwner &&
            (this.isTextMessage || this.isPhotoMessage || this.isDocumentMessage));
    }
    get canDelete() {
        return this.isOwner && !this.isDeletedMessage;
    }
    get canPin() {
        return (this.isTextMessage ||
            this.isPhotoMessage ||
            this.isDocumentMessage ||
            this.isContactMessage ||
            this.isForwardMessage);
    }
    get chat() {
        if (!this.chatId) {
            return undefined;
        }
        return this.vchat.chat(this.chatId);
    }
    get isPinned() {
        var _a;
        return (this.id && this.chat && ((_a = this.chat) === null || _a === void 0 ? void 0 : _a.pinnedMessageId.indexOf(this.id)) >= 0);
    }
    get isSending() {
        return this.state == "sending";
    }
    get wasSent() {
        return this.state == "new";
    }
    get wasSeen() {
        return this.id && this.chat && this.chat.maxOutgoingReadId >= this.id;
    }
    get thumbnailDownload() {
        const photo = this.photo && this.photo[0];
        return photo && this.vchat.fileDownload(photo.fileId);
    }
    get thumbnailUrl() {
        if (this.isDraftPhotoMessage && this.file) {
            const objectUrl = window.URL.createObjectURL(this.file);
            return objectUrl;
        }
        if (this.isPhotoMessage) {
            const fileDownload = this.thumbnailDownload;
            return fileDownload === null || fileDownload === void 0 ? void 0 : fileDownload.objectUrl;
        }
        return undefined;
    }
    get photoDownload() {
        var _a;
        const photo = this.photo && ((_a = this.photo[1]) !== null && _a !== void 0 ? _a : this.photo[0]);
        return photo && this.vchat.fileDownload(photo.fileId);
    }
    get photoUrl() {
        if (this.isDraftPhotoMessage && this.file) {
            const objectUrl = window.URL.createObjectURL(this.file);
            return objectUrl;
        }
        if (this.isPhotoMessage) {
            const fileDownload = this.photoDownload;
            return fileDownload === null || fileDownload === void 0 ? void 0 : fileDownload.objectUrl;
        }
        return undefined;
    }
    get documentDownload() {
        const document = this.document;
        return document && this.vchat.fileDownload(document.fileId);
    }
    get documentUrl() {
        var _a;
        return (_a = this.documentDownload) === null || _a === void 0 ? void 0 : _a.objectUrl;
    }
    get fileUpload() {
        return this.uploadId ? this.vchat.fileUpload(this.uploadId) : undefined;
    }
    loadThumbnail() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.id || !this.chatId) {
                return;
            }
            if (!this.thumbnailDownload || !this.thumbnailUrl) {
                const photo = this.photo && this.photo[0];
                if (!photo) {
                    return;
                }
                yield this.vchat.downloadChatPhoto(this.chatId, this.id, photo.fileId);
            }
            return this.thumbnailUrl;
        });
    }
    loadPhoto() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.id || !this.chatId) {
                return;
            }
            if (!this.photoDownload || !this.photoUrl) {
                const photo = this.photo && ((_a = this.photo[1]) !== null && _a !== void 0 ? _a : this.photo[0]);
                if (!photo) {
                    return;
                }
                yield this.vchat.downloadChatPhoto(this.chatId, this.id, photo.fileId);
            }
            return this.photoUrl;
        });
    }
    loadDocument() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.id || !this.chatId) {
                return;
            }
            if (!this.documentDownload) {
                const document = this.document;
                if (!document) {
                    return;
                }
                yield this.vchat.downloadChatDocument(this.chatId, this.id, document.fileId, document.fileName, document.mimeType);
            }
        });
    }
}
exports.Message = Message;
