// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  vchat_secure: true,
  vchat_host: 'vchat-ws.devitkv2.com:443',
  vchat_app_id: '607d0060cf80802e08b6cb33',
  vchat_app_key: 'N7SXqGLU6E0ywtdcjrZtjSqVy2Pp8rwu',
  // KTNN
  // vchat_secure: true,
  // vchat_host: 'vdchat.sav.gov.vn:443',
  // vchat_app_id: '6225d2a7893b4664b2fe60e6',
  // vchat_app_key: 'ewTRDt0WE4driZUYVF0u86GVZGapOpN3',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
