import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng/api';
import VNPTChat, { Chat, Contact, User } from 'vnpt-chat';
import { DialogService } from 'primeng/dynamicdialog';
import { CreateGroupChatModalComponent } from '../create-group-chat-modal/create-group-chat-modal.component';
import { FindUsersModalComponent } from '../find-users-modal/find-users-modal.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  items: MenuItem[] = [{ label: 'Trang chủ' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/' };

  showInfo = false;

  vchat = VNPTChat.instance;

  get unreadCount() {
    return this.vchat.totalUnreadCount;
  }
  get unreadCountString() {
    if (!this.unreadCount) {
      return '';
    }
    return this.unreadCount.toFixed();
  }

  selectedChat?: Chat;
  selectedUser?: User;
  selectedContact?: Contact;

  text: string = '';

  constructor(public dialogService: DialogService) {}

  ngOnInit(): void {}

  onChatSelected(chat: Chat) {
    this.selectedChat = chat;
    if (chat.isIndividual) {
      this.selectedUser = chat.userPartner;
      this.selectedContact =
        this.selectedUser && this.vchat.contact(this.selectedUser?.id);
    } else {
      this.selectedUser = undefined;
      this.selectedContact = undefined;
    }
  }

  async onContactSelected(contact: Contact) {
    const userId = contact.userId;
    const user = await this.vchat.getUser(userId);
    this.selectedUser = user;
    this.selectedContact = contact;
    const chat = await this.vchat.getChatByReceiver(userId);
    this.selectedChat = chat;
  }

  showAddContact() {
    const ref = this.dialogService.open(FindUsersModalComponent, {
      header: 'Find Contact',
      width: '70%',
    });
    ref.onClose.subscribe(async (user) => {
      const userId = user.id;
      this.selectedUser = user;
      this.selectedContact = this.vchat.contact(userId);
      const chat = await this.vchat.getChatByReceiver(userId);
      this.selectedChat = chat;
    });
  }

  showCreateGroupChat() {
    const ref = this.dialogService.open(CreateGroupChatModalComponent, {
      header: 'Create Group Chat',
      width: '70%',
    });
    ref.onClose.subscribe((group) => {
      if (group) {
        this.onChatSelected(group);
      }
    });
  }

  async onSenderClick(sender: User) {
    this.selectedUser = sender;
    this.selectedContact = this.vchat.contact(sender.id);
    const chat = await this.vchat.getChatByReceiver(sender.id);
    this.selectedChat = chat;
    this.showInfo = true;
  }
}
