import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home/home.component';
import { SplitterModule } from 'primeng/splitter';
import { TabViewModule } from 'primeng/tabview';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ConfirmPopupModule } from 'primeng/confirmpopup';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { AddContactComponent } from './add-contact/add-contact.component';
import { ConfirmationService } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { NgxVnptChatModule } from 'ngx-vnpt-chat';

@NgModule({
  declarations: [HomeComponent, AddContactComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HomeRoutingModule,
    SplitterModule,
    TabViewModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    DynamicDialogModule,
    ConfirmPopupModule,
    NgxVnptChatModule,
    BadgeModule,
  ],
  exports: [],
  providers: [DialogService, ConfirmationService],
})
export class HomeModule {}
