"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.User = void 0;
const vnptchat_1 = require("../vnptchat");
class User {
    constructor(user) {
        this.vchat = vnptchat_1.VNPTChat.instance;
        if (!user) {
            return;
        }
        this.id = user.id;
        this.externalId = user.externalId;
        this.username = user.username;
        this.displayName = user.displayName;
        this.onlineState = user.onlineState;
        this.avatarColor = user.avatarColor;
        this.avatar = user.avatar;
        this.pinnedChatId = user.pinnedChatId;
        this.createdAt = user.createdAt;
        this.updatedAt = user.updatedAt;
    }
    get avatarUrl() {
        const avatar = this.avatar && this.avatar[0];
        const fileDownload = avatar && this.vchat.fileDownload(avatar.fileId);
        return fileDownload === null || fileDownload === void 0 ? void 0 : fileDownload.objectUrl;
    }
    get individualChat() {
        return this.vchat.individualChat(this.id);
    }
    loadAvatar() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.avatarUrl) {
                const avatar = this.avatar && this.avatar[0];
                avatar && (yield this.vchat.downloadUserAvatar(this.id, avatar.fileId));
            }
            return this.avatarUrl;
        });
    }
    getChat() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.vchat.getChatByReceiver(this.id);
        });
    }
    sendText(text, options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.vchat.sendTextToUser(this.id, text, options);
        });
    }
    sendPhoto(photo, options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.vchat.sendPhotoToUser(this.id, photo, options);
        });
    }
    sendDocument(file, options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.vchat.sendDocumentToUser(this.id, file, options);
        });
    }
    shareContact(sharedUserId, options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.vchat.shareContactToUser(this.id, sharedUserId, options);
        });
    }
    forwardMessage(fromChatId, messageId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.vchat.forwardMessageToUser(this.id, fromChatId, messageId);
        });
    }
    sendAction(action) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.vchat.sendActionToUser(this.id, action);
        });
    }
    sendCallLog(callType, startedAt, endedAt, callDuration, endReason) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.vchat.sendCallLogToUser(this.id, callType, startedAt, endedAt, callDuration, endReason);
        });
    }
}
exports.User = User;
