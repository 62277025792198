import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  trigger,
  style,
  transition,
  animate,
  AnimationEvent,
} from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';
import VNPTChat from 'vnpt-chat';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  animations: [
    trigger('overlayMenuAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scaleY(0.8)' }),
        animate(
          '.12s cubic-bezier(0, 0, 0.2, 1)',
          style({ opacity: 1, transform: '*' })
        ),
      ]),
      transition(':leave', [animate('.1s linear', style({ opacity: 0 }))]),
    ]),
  ],
})
export class TopbarComponent implements OnInit {
  loggedIn = this.authService.loggedIn;
  vchat = VNPTChat.instance;

  get currentUser() {
    return this.vchat.currentUser;
  }
  get displayName() {
    return this.authService.displayName;
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private sanitizer: DomSanitizer
  ) {
    this.authService.loggedIn$.subscribe((logged) => {
      this.loggedIn = logged;
    });
  }

  ngOnInit(): void {
    //
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activeMenuIndex = null;
      }
    });
  }

  logout() {
    this.activeMenuIndex = null;
    this.authService.logout();
    this.router.navigateByUrl('/auth/login');
  }

  @ViewChild('topbarMenu') topbarMenu: ElementRef | null = null;

  activeMenuIndex: number | null = null;

  outsideClickListener: any;

  toggleMenu(event: Event, index: number) {
    this.activeMenuIndex = this.activeMenuIndex === index ? null : index;
    event.preventDefault();
  }

  onOverlayMenuEnter(event: AnimationEvent) {
    switch (event.toState) {
      case 'visible':
        this.bindOutsideClickListener();
        break;

      case 'void':
        this.unbindOutsideClickListener();
        break;
    }
  }

  bindOutsideClickListener() {
    if (!this.outsideClickListener) {
      this.outsideClickListener = (event: any) => {
        if (this.isOutsideTopbarMenuClicked(event)) {
          this.activeMenuIndex = null;
        }
      };

      document.addEventListener('click', this.outsideClickListener);
    }
  }

  unbindOutsideClickListener() {
    if (this.outsideClickListener) {
      document.removeEventListener('click', this.outsideClickListener);
      this.outsideClickListener = null;
    }
  }

  isOutsideTopbarMenuClicked(event: any): boolean {
    return !(
      this.topbarMenu?.nativeElement.isSameNode(event.target) ||
      this.topbarMenu?.nativeElement.contains(event.target)
    );
  }
}
