import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { User } from 'vnpt-chat';

@Component({
  selector: 'app-find-users-modal',
  templateUrl: './find-users-modal.component.html',
  styleUrls: ['./find-users-modal.component.scss'],
})
export class FindUsersModalComponent implements OnInit {
  constructor(public ref: DynamicDialogRef) {}

  ngOnInit(): void {}

  onSelect(user: User) {
    this.ref.close(user);
  }
}
