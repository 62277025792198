import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import VNPTChat from 'vnpt-chat';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss'],
})
export class AddContactComponent implements OnInit {
  vchat = VNPTChat.instance;

  displayName = '';

  get userId() {
    return this.config.data.userId;
  }

  get user() {
    return this.vchat.user(this.userId);
  }

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.displayName = this.user?.displayName ?? '';
  }

  addContact() {
    this.vchat.createContact(this.userId, this.displayName);
    this.ref.close();
  }

  cancel() {
    this.ref.close();
  }
}
