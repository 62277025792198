import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Chat } from 'vnpt-chat';

@Component({
  selector: 'app-create-group-chat-modal',
  templateUrl: './create-group-chat-modal.component.html',
  styleUrls: ['./create-group-chat-modal.component.scss'],
})
export class CreateGroupChatModalComponent implements OnInit {
  constructor(public ref: DynamicDialogRef) {}

  ngOnInit(): void {}

  onCreated(group: Chat) {
    this.ref.close(group);
  }
}
