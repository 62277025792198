import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

import { TopbarComponent } from './layout/topbar/topbar.component';
import { FooterComponent } from './layout/footer/footer.component';
import { MenuComponent } from './layout/menu/menu.component';
import { MainComponent } from './layout/main/main.component';
import { AuthComponent } from './layout/auth/auth.component';
import { HomeModule } from './home/home.module';
import { NgxVnptChatModule, VChatServiceConfig } from 'ngx-vnpt-chat';
import { CreateGroupChatModalComponent } from './home/create-group-chat-modal/create-group-chat-modal.component';
import { FindUsersModalComponent } from './home/find-users-modal/find-users-modal.component';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    TopbarComponent,
    FooterComponent,
    MenuComponent,
    MainComponent,
    AuthComponent,
    CreateGroupChatModalComponent,
    FindUsersModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ToastModule,
    HomeModule,
    NgxVnptChatModule,
  ],
  providers: [
    MessageService,
    {
      provide: VChatServiceConfig,
      useValue: {
        host: environment.vchat_host,
        secure: environment.vchat_secure,
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
