<form (ngSubmit)="addContact()">
  <div class="p-fluid">
    <div class="p-field">
      <input
        pInputText
        type="text"
        [(ngModel)]="displayName"
        name="displayName"
        autofocus
      />
    </div>
  </div>

  <div style="text-align: right">
    <button
      pButton
      type="button"
      class="p-d-inline-block p-mr-2 p-button-secondary"
      (click)="cancel()"
    >
      Cancel
    </button>
    <button pButton type="submit" class="p-d-inline-block">Add</button>
  </div>
</form>
